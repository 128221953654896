<!-- 基本信息 -->
<template>
  <div class="wrap">
    <div class="title">账户信息</div>
    <div class="content">
      <div class="content__left">
        <div class="content__box">
          <div class="content__row">
            <span class="content__name">登录账户：</span><span>{{ distributorVO.applyMobile }}</span>
          </div>
          <div class="content__row">
            <span class="content__name">店铺名称：</span><span>{{ distributorVO.shopName }}</span>
          </div>
        </div>
        <div class="content__box">
          <div class="content__row">
            <span class="content__name">商家类型：</span
            ><span>{{ distributorVO.merchantTypeName }}</span>
          </div>
          <div class="content__row">
            <span class="content__name">联系人：</span><span>{{ distributorVO.contactName }}</span>
          </div>
        </div>
        <div class="content__box">
          <div class="content__row">
              <span class="content__name">联系方式：</span><span>{{ distributorVO.contactPhone }}</span>
            </div>
          <div class="content__row">
            <span class="content__name">联系邮箱：</span><span>{{ distributorVO.email }}</span>
          </div>
        </div>
        <div class="content__row" style="width: 100%;">
          <span class="content__name">收货地址：</span
          ><span>{{ userConsigneeAddressVO | parsingAddRess }}</span>
          <div class="content__btn--text">
            <router-link to="/syoung/information/list">
              <el-button size="small" type="text" class="common-color-b">管理地址</el-button>
            </router-link>
          </div>
        </div>
      </div> 
    </div>

    <!-- 主体信息 -->
    <div class="title">
      <span style="margin-right: 15px;">主体信息</span>
      <el-button size="small" @click="addContractInfo" type="primary">添加主体</el-button>
    </div>
    <el-table
      :data="subjectInfoList"
      style="width: 100%"
      border
      max-height="600px"
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column align="left" label="主体类型" width="80px">
        <template slot-scope="scope">
          <template v-if="scope.row.merchantType === 'ENTERPRISE'">企业</template>
          <template v-else-if="scope.row.merchantType === 'PERSONAL'">个人</template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column align="left" label="主体名称" min-width="100px">
        <template slot-scope="scope">
          <template v-if="scope.row.merchantType === 'ENTERPRISE'">{{
            scope.row.company
          }}</template>
          <template v-else-if="scope.row.merchantType === 'PERSONAL'">{{ scope.row.contactRealName }}</template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column align="left" label="甲方主体名称" prop="ownCompanyName" min-width="100px">
        <template slot-scope="scope">
          <p v-if="scope.row.ownCompanyName">{{ scope.row.ownCompanyName }}</p>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column align="left" label="联系人信息" min-width="110px">
        <template slot-scope="scope">
          <div v-if="scope.row.contactRealName">
            <p>联系人：{{ scope.row.contactRealName }}</p>
            <p>证件号码：{{ scope.row.contactIdcardNo }}</p>
            <p>联系电话：{{ scope.row.contactPhone }}</p>
            <p>联系地区：{{ scope.row.contactAddress }}</p>
          </div>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="银行卡信息" min-width="130px">
        <template slot-scope="scope">
          <template v-if="scope.row.bankCardVO || scope.row.bankCardVOList">
            <div v-if="scope.row.id">
              <div v-if="scope.row.bankCardVOList">
                <p>开户名：{{ scope.row.bankCardVOList[0].cardholder }}</p>
                <p>开户行：{{ scope.row.bankCardVOList[0].bankName }}</p>
                <p>账户：{{ scope.row.bankCardVOList[0].cardNumber }} <el-tag size="mini" :type="scope.row.bankCardVOList[0].status | tagType">{{ scope.row.bankCardVOList[0].status | parseStatus }}</el-tag></p>
                <p v-if="scope.row.bankCardVOList.length > 1">
                  <router-link :to="'/syoung/shop/view-subject-information/' + scope.row.distributorContractInfoId">
                    <el-button size="small" type="text" class="common-color-b">{{'查看全部>'}}</el-button>
                  </router-link>
                </p>
              </div>
            </div>
            <div v-else>
              <div v-if="scope.row.bankCardVO">
                <p>开户名：{{ scope.row.bankCardVO.cardholder }}</p>
                <p>开户行：{{ scope.row.bankCardVO.bankName }}</p>
                <p>账户：{{ scope.row.bankCardVO.cardNumber }} <el-tag size="mini" :type="scope.row.bankCardVO.status | tagType">{{ scope.row.bankCardVO.status | parseStatus }}</el-tag></p>
              </div>
            </div>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="left" label="合同数" prop="contractQuantity">
        <template slot-scope="scope">
          <template>{{ scope.row.contractQuantity || 0 }}</template>
        </template>
      </el-table-column>

      <el-table-column align="left" label="操作" width="120px">
        <template slot-scope="scope">
          <el-button size="small" @click="detail(scope.row.distributorContractInfoId)" type="text" :disabled="!scope.row.distributorContractInfoId">查看</el-button>
          <el-button size="small" @click="edit(scope.row)" type="text" v-if="scope.row.distributorContractInfoId">编辑</el-button>
          <el-button size="small" @click="handPerfect(scope.row)" type="text" v-else>完善信息</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getBasicInfo, listDistributorContractInfo } from '@/api/shop';

export default {
  name: 'BasicInfo',
  components: {},
  data() {
    return {
      distributorVO: {}, // 分销商账户信息
      invoiceTitleVO: {}, // 发票抬头信息
      userConsigneeAddressVO: {}, //分销商默认收货地址
      /* 
      company (string, optional): 公司名称 ,
      contactAddress (string, optional): 详细地址 ,
      contactEmail (string, optional): 联系人邮箱 ,
      contactIdcardNo (string, optional): 联系人身份证号 ,
      contactPhone (string, optional): 联系人电话 ,
      contactRealName (string, optional): 联系人真实姓名 ,
      contractQuantity (integer, optional): 合同数量 ,
      distributorId (string, optional): 分销商ID ,
      distributorMerchantType (string, optional): 当前分销商类型 ,
      id (string, optional): 合同基本信息ID，等同于分销商ID ,
      isHasContract (string, optional): 是否有合同占用,有则不可以编辑：0-否,1-是 ,
      memberId (string, optional): 用户ID ,
      taxNo (string, optional): 企业税号
      */
      subjectInfoList: [], // 主体信息
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
      headerRowStyle: { color: '#333333' },
    };
  },
  mounted() {
    this.fetchBasicInfo();
  },
  filters: {
    parsingAddRess(address) {
      if (Object.keys(address).length === 0) return '暂未填写';
      const {
        provinceName = '',
        cityName = '',
        zoneName = '',
        addressDetail = '',
        consignee = '未填写收货人',
        mobile = '未填写电话',
      } = address;
      return `${consignee}，${mobile}，${provinceName}${cityName}${zoneName}${addressDetail}`;
    },
    parseStatus(status) {
      return status === '0' ? '不可用' : '可用';
    },
    tagType(status) {
      return status === '0' ? 'info' : 'success';
    }
  },
  methods: {
    // 获取基本信息
    fetchBasicInfo() {
      getBasicInfo().then(res => {
        this.distributorVO = res.data.distributorVO || {};
        this.invoiceTitleVO = res.data.invoiceTitleVO || {};
        this.userConsigneeAddressVO = res.data.userConsigneeAddressVO || {};

        this.distributorId = this.distributorVO.id;
        this.$router.push({ query: {} });
        this.fetchDistributorContractInfo();
      });
    },
    fetchDistributorContractInfo() {
      if (this.distributorId) {
        listDistributorContractInfo({
          distributorId: this.distributorId,
        }).then(res => {
          this.subjectInfoList = res.data;
        });
      }
    },
    // 添加主体信息
    addContractInfo() {
      this.$router.push('/syoung/shop/contract-info');
    },
    // 查看主体信息
    detail(id) {
      this.$router.push({
        name: '/syoung/shop/view-subject-information',
        params: {
          id: id
        }
      });
    },
    // 编辑
    edit(row) {
      this.$router.push({
        name: '/syoung/shop/contract-info',
        query: {
          id: row.distributorContractInfoId,
          isHasContract: row.isHasContract
        }
      });
    },
    // 完善信息
    handPerfect(row) {
      if(!row.bankCardVO) return this.$message({ message: '银行卡信息不存在', type: 'warning' });
      this.$router.push(`/syoung/shop/improve-information?id=${row.bankCardVO.id}`);
    }
  },
};
</script>

<style lang="scss" scoped>
@import './basic-info';
</style>