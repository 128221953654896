<!-- 我的授权详情 有店-->
<template>
  <div class="brand-my-detail">
    <el-button type="text" @click="onDoubt"><i class="el-icon-question">授权统计规则</i></el-button>
    <div class="brand-my-detail-head">
      <div v-for="item of brandList" :key="item.brandId" class="brandbox">
        <img class="img" :src="item.logoUrl" :alt="item.brandName" />
        <div class="name">{{ item.brandName }}</div>
      </div>
    </div>
    <div class="brand-my-detail-body">
      <div class="conditions" v-for="(item, i) of thresholdList" :key="i">
        <div class="left">
          <div class="title">条件{{ (i + 1) | numberConvertToUppercase }}：{{ item.txt }}</div>
          <div v-if="item.date">
            {{ item.date }} <span v-if="item.tip2" class="tip2">{{ item.tip2 }}</span>
          </div>
          <div v-if="item.tip">
            {{ item.tip }}
          </div>
        </div>
        <div class="right">
          <div class="conditions-purchase">
            <i :class="item.isSuccess ? 'el-icon-success' : 'el-icon-error'"></i>
            <span>{{ item.isSuccess ? '已' : '未' }}达成</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { brandLicenseListMyBrandLicense, brandLicenseConfigGet } from '@/api/brandauthorization';
import { parseTime } from '@/utils/date';
export default {
  data() {
    return {
      brandList: [], // 品牌列表
      thresholdList: [], // 门槛列表
      details: {}, //所有数据
      ruleText: '', // 规则
    };
  },
  name: 'brandMyDetail',
  components: {},
  computed: {},

  created() {
    this.getDetails();
    brandLicenseConfigGet().then((res) => {
      this.ruleText = res.data.ruleText.replace(/[(\r\n)|(\n)|(↵)]+/g, '\n');
    });
  },

  mounted() {},
  methods: {
    onDoubt() {
      this.$alert(this.ruleText);
    },
    // 达成详情
    getDetails() {
      const { channel, shopType, brandId, tradeType } = this.YDialogInitData;
      const parameter = {
        channel,
        shopType,
        brandId,
        tradeType,
      };
      brandLicenseListMyBrandLicense(parameter).then((res) => {
        const data = res.data;
        if (data.firstPurchaseOrderList && data.firstPurchaseOrderList.length > 5) {
          data.firstPurchaseOrderListOne = data.firstPurchaseOrderList.slice(0, 5);
          data.firstPurchaseOrderListMore = data.firstPurchaseOrderList.slice(5);
        } else {
          data.firstPurchaseOrderListOne = data.firstPurchaseOrderList;
        }
        this.details = data;
        this.setShopData();
      });
    },
    // 有店
    setShopData() {
      const {
        firstPurchaseAmount,
        isFirstPurchase,
        depositAmount,
        isDeposit,
        totalPurchaseAmount,
        isTotalPurchase,
        firstPurchaseStartDate,
        firstPurchaseEndDate,
        totalPurchaseStartDate,
        totalPurchaseEndDate,
        actualDepositAmount,
        actualTotalPurchaseAmount,
      } = this.details;
      const { brandName, logoUrl } = this.YDialogInitData;
      const thresholdList = []; // 门槛列表
      this.brandList = [{ brandName, logoUrl }];
      if (firstPurchaseAmount) {
        thresholdList.push({
          txt: `首采门槛${firstPurchaseAmount}元（单笔订单）`,
          isSuccess: isFirstPurchase === '1',
          date: `首采统计时间：${parseTime(firstPurchaseStartDate, '{y}.{m}.{d}')}-${parseTime(
            firstPurchaseEndDate,
            '{y}.{m}.{d}'
          )}`,
        });
      }
      if (depositAmount) {
        thresholdList.push({
          txt: `缴纳保证金${depositAmount}元`,
          isSuccess: isDeposit === '1',
          tip: `缴纳保证金：${actualDepositAmount}元`,
        });
      }
      if (totalPurchaseAmount) {
        thresholdList.push({
          txt: `累计采货${totalPurchaseAmount}元`,
          isSuccess: isTotalPurchase === '1',
          date: `累计采货统计时间：${parseTime(totalPurchaseStartDate, '{y}.{m}.{d}')}-${parseTime(
            totalPurchaseEndDate,
            '{y}.{m}.{d}'
          )}`,
          tip: `累计采货金额：${actualTotalPurchaseAmount}元`,
          tip2: '（统计近35天能达到门槛的采购总额）',
        });
      }
      this.thresholdList = thresholdList;
    },
  },
};
</script>
<style lang='scss' scoped>
.brand-my-detail-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .brandbox {
    text-align: center;
    margin: 19px 15px;
    .img {
      width: 55px;
      height: 55px;
    }
    .name {
      font-size: 14px;
      color: #363636;
      margin-top: 5px;
    }
  }
}
.brand-my-detail-body {
  .conditions {
    display: flex;
    align-items: center;
    background: #ecf4fd;
    margin-bottom: 20px;
    border: 1px solid #ecf4fd;
    padding: 16px 13px 19px 27px;
    color: #333333;
    font-size: 14px;
    .left {
      flex: 1;
      padding-right: 20px;
      & > div {
        margin: 17px 0;
      }
      > .title {
        font-weight: bold;
      }
      .tip2 {
        font-size: 12px;
        color: #666;
        display: block;
        margin-top: 5px;
      }
    }
    .conditions-purchase {
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 5px;
      }

      .el-icon-success {
        color: #AB0033;;
      }
      .el-icon-error {
        color: #999999;
      }
    }
  }
}
</style>