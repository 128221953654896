<template>
  <el-upload
    v-bind="$attrs"
    ref="my-upload"
    :action="action"
    :headers="headers"
    name="file"
    :data="data"
    with-credentials
    :on-success="onUploadSuccess"
    :on-error="onUploadError"
    :on-remove="onUploadRemove"
    :on-exceed="handleExceed"
    class="my-upload"
    :class="{ 'is-disabled': disabled }"
    :disabled="disabled"
  >
    <slot></slot>
    <template v-if="isSlotFile" slot="file" slot-scope="{ file }">
      <slot name="file" :file="file"></slot>
    </template>
  </el-upload>
</template>
<script>
// 这个upload有个缺陷 就是只能处理同步的行为 处理并发以及并发中断有待优化
import { getHeaders, getRes } from '@/utils/request';
import { mapGetters } from 'vuex';
export default {
  name: 'upload',
  inheritAttrs: false,
  data() {
    return {
      action: `${process.env.VUE_APP_BASE_API}/file/api/file/${this.isPrivate ? 'uploadPrivate' : 'upload'}`,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    data() {
      return this.group && { group: this.group };
    },
    headers() {
      const { userToken = '' } = this.userInfo || {};
      return {
        ...getHeaders(),
        'X-User-Token': userToken,
      };
    },
  },
  methods: {
    clearFiles(){
      this.$refs['my-upload'] && this.$refs['my-upload'].clearFiles();
    },
    beforeUpload(file) {
      if(!this.isValid){
        return;
      }
      // 校验文件格式
      const FileExt = file.name.replace(/.+\./, '');
      const accept = this.$attrs.accept;
      if(accept){
        const fileTypes = this.$attrs.accept.replace(/\./g, '').split(',');
        const extension = fileTypes.includes(FileExt.toLowerCase());
        if (!extension){
          this.$message.warning(`禁止上传${FileExt}文件格式的文件`);
          return false;
        }
      }
      // 校验文件大小
      const isLt = file.size / 1024 / 1024 < this.maxCapacity;
      if (!isLt) {
        this.$message.warning(`文件大小超限，文件不能超过 ${this.maxCapacity}M`);
        return false;
      }
    },
    onUploadSuccess(response, ...args) {
      getRes(response)
        .then((res) => {
          this.$message.success('上传成功');
          this.onSuccess(res, ...args);
        })
        .catch((err) => {
          if (this.onError) {
            this.onError(err);
          }
        });
    },
    onUploadError(err) {
      this.$notify.error(err.message || '上传失败');
      if (this.onError) {
        this.onError(err);
      }
    },
    onUploadRemove(file, fileList) {
      this.onRemove(file, fileList);
    },
    handleExceed(files, fileList){
      const { limit = '' } = this.$attrs;
      if(!limit){
        return;
      }
      this.$message.warning(`当前限制选择 ${limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    }
  },
  props: {
    isPrivate: Boolean, // 是否是私密上传
    isSlotFile: Boolean, // 是否开启自定义File插槽
    isValid: Boolean, // 是否需要校验文件
    maxCapacity: Number, // 最大上传容量
    group: String,
    onSuccess: Function,
    onRemove: Function,
    onError: Function,
    disabled: {
      type: Boolean,
      default: false,
    }, // 是否禁用 默认不禁用
  },
};
</script>
<style lang="scss" scoped>
.my-upload {
  &.is-disabled {
    ::v-deep {
      .el-upload--picture-card {
        background: #f7f8fa;
        color: #9ea4b2 !important;
        cursor: no-drop;
      }
    }
  }
  /deep/ {
    .el-loading-spinner {
      position: relative;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }
}
</style>

